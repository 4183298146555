@import ../../styles/helpers

.chartCard 
  background: $n1
  padding: 20px
  border-radius: 8px
  color: $n8
  text-align: center
  max-width: 250px
  margin: auto
  +dark
    background: $n7
    color: $n2

.title 
  font-size: 18px
  margin-bottom: 10px


.doughnutContainer 
  position: relative
  width: 150px
  margin: 0 auto


.percentage 
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  color: $n8
  font-size: 15px
  text-align: center
  +dark
    background: $n7
    color: $n2

.month, .dateRange 
  margin-top: 10px
  font-size: 14px
  color: $n8
  +dark
    color: $n2

