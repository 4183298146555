@import '../../styles/helpers'

.locationmapcontainer 
  svg 
    path 
      transition: stroke-width 0.3s ease, transform 0.3s ease
      stroke: $n4
      stroke-width: 0.7
      fill: #f1f1f1
      transform-origin: 50% 50%

      +dark 
        stroke: #99a1ae
        stroke-width: 0.7
        fill: #1f1f1f
      

      &:hover 
        stroke-width: 1.5
        transform: scale(1.05)
      
    
  


.map 
  display: flex
  justify-content: center

  // Styles for Sri Lanka map
  &.sriLanka
    transform: scale(1) // Default scale for larger screens
    transform-origin: 50% 30%
    width: 100vw // Default width for larger screens
    height: 80vh // Default height for larger screens  
    
  

  // Styles for World map
  &.world 
    width: 100vw // Default width for larger screens
    height: 80vh // Default height for larger screens

    svg 
      width: 100%
      height: 100%
    

    // Add specific path styles for the world map
    path 
      +m 
        transform-origin: center
      
    
  


// Media query for mobile devices (screen width < 768px)
@media (max-width: 768px) 
  .map 
    &.sriLanka
        width: 120vw // Default width for larger screens
        height: 100vh // Default height for larger screens  
        transform: scale(0.7) // Apply 0.7 scale for mobile
        transform-origin: center
        transform-origin: 20% 30%
      
    

    &.world 
        width: 220vw // Adjust width to full viewport width on mobile
        height: 70vh // Adjust height to auto on mobile
        transform: scale(0.5)
        transform-origin: center
        transform-origin: -15% 30%



        // Adjust path scaling for mobile
        path 
            transform: scale(0.7) // Apply 0.7 scale for mobile
            transform-origin: center
        
    
  

