/* login.module.scss */

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f4f8;
}

.card {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
}

.inputGroup {
  margin-bottom: 1.5rem;
  text-align: left;
}

.label {
  font-size: 0.875rem;
  color: #555;
  margin-bottom: 0.5rem;
  display: block;
}

.input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
  }
}

.errorMessage {
  color: #e74c3c;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.button {
  width: 100%;
  background-color: #ffffff;
  color: #007bff;
  border: 2px solid #007bff;
  padding: 0.75rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #007bff;
    color: #ffffff;
  }
}

.link {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
