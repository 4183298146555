// common styles 
@import ./reset
@import ./helpers
@import ./common

// global classes
@import ./blocks/container
@import ./blocks/headers
@import ./blocks/button
@import ./blocks/status
@import ./blocks/title
@import ./blocks/panel
@import ./blocks/datepicker
@import ./blocks/slick
@import ./blocks/editor
@import ./blocks/reactTags


body 

// styles/app.scss

// Import Tailwind base styles if you haven't already
@import "tailwindcss/base"
@import "tailwindcss/components"
@import "tailwindcss/utilities"

// Additional custom styles
.signin-signup-container 
  display: flex
  align-items: center
  justify-content: center
  height: 100vh
  padding: 1rem

  .image-container 
    width: 100%
    max-width: 400px
    margin-bottom: 2rem
    
    @media (min-width: 1024px) 
      margin-bottom: 0
    
  

  img 
    width: 100%
    height: auto
    border-radius: 0.5rem
  

  .form-container 
    width: 100%
    max-width: 400px
    padding: 2rem
    background-color: #ffffff
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1)
    border-radius: 0.5rem

    @media (min-width: 1024px) 
      margin-left: 2rem
    

    form 
      display: flex
      flex-direction: column

      .social-buttons 
        display: flex
        justify-content: center
        margin-bottom: 1.5rem

        button 
          display: flex
          align-items: center
          justify-content: center
          width: 36px
          height: 36px
          border-radius: 50%
          background-color: #1d4ed8
          color: #fff
          margin-right: 0.5rem
          transition: background-color 0.3s ease

          &:hover 
            background-color: #2563eb
          

          &:last-child 
            margin-right: 0
          

          svg 
            width: 16px
            height: 16px

      .input-field 
        margin-bottom: 1.5rem

        label 
          display: block
          margin-bottom: 0.5rem
          font-weight: 600
          color: #374151
        

        input 
          width: 100%
          padding: 0.75rem
          border: 1px solid #d1d5db
          border-radius: 0.375rem
          outline: none
          transition: border-color 0.3s ease

          &:focus 
            border-color: #2563eb
          
        
      

      .actions 
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 1.5rem

        .checkbox-group 
          display: flex
          align-items: center

          input 
            margin-right: 0.5rem
            accent-color: #2563eb
          

          label 
            font-size: 0.875rem
            color: #4b5563
          
        

        a 
          font-size: 0.875rem
          color: #2563eb
          text-decoration: underline

          &:hover 
            color: #1d4ed8
          
        
      

      .submit-btn 
        background-color: #2563eb
        color: #fff
        padding: 0.75rem 1.5rem
        border-radius: 0.375rem
        text-align: center
        transition: background-color 0.3s ease

        &:hover 
          background-color: #1d4ed8
        
      

      .login-link 
        margin-top: 1rem
        text-align: center
        font-size: 0.875rem

        a 
          color: #2563eb
          text-decoration: underline

          &:hover 
            color: #1d4ed8
          
