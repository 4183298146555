@import ../../styles/helpers
@import ../../styles/helpers

.background
    height: 400px
    margin: -40px -40px 0
    +x
        margin: -32px -24px 0
    +d
        height: 320px
    +m
        height: 252px
        margin: -24px -16px 0
    img
        width: 100%
        height: 100%
        object-fit: cover

.card
    max-width: 1200px
    margin: -64px auto 0
    +m
        margin-top: -188px

.control
    display: flex
    align-items: center
    margin-bottom: 32px
    +m
        flex-wrap: wrap

.nav
    display: flex
    margin-right: auto
    +m
        width: 100%
        margin: 0 0 16px

.link
    padding: 8px 16px
    border-radius: 8px
    +base1-s
    color: $n4
    transition: all .2s
    +m
        flex-grow: 1
    &:hover,
    &.active
        color: $n7
        +dark
            color: $n1
    &.active
        background: $n3
        +dark
            background: $n6
    &:not(:last-child)
        margin-right: 8px
        +m
            margin-right: 0

.dropdownBox
    +m
        flex-grow: 1

.filters
    margin-left: 16px
    +m
        margin-left: 12px

.products
    display: flex
    flex-wrap: wrap
    margin: -32px -12px 0
    +a
        display: block
        margin: 0

.product
    flex: 0 0 calc(33.333% - 24px)
    width: calc(33.333% - 24px)
    margin: 32px 12px 0
    +t
        flex: 0 0 calc(50% - 24px)
        width: calc(50% - 24px)
    +a
        width: 100%
        margin: 24px 0 0

.foot
    margin-top: 40px
    text-align: center
    +x
        margin-top: 32px
    +m
        margin-top: 24px

.row
    display: flex
    +t
        display: block

.col
    &:nth-child(2)
        flex: 0 0 calc(100% - 340px)
        width: calc(100% - 340px)
        padding-right: 8px
        +x
            flex: 0 0 calc(100% - 324px)
            width: calc(100% - 324px)
        +d
            flex: 0 0 calc(100% - 312px)
            width: calc(100% - 312px)
        +t
            width: 100%
            margin-bottom: 8px
            padding-right: 0
    &:first-child
        flex-shrink: 0
        width: 340px
        +x
            width: 324px
        +d
            width: 312px
        +t
            width: 100%