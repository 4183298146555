@import ../../styles/helpers

.container  
  background-color: white
  padding: 20px 
  border-radius: 10px 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) 
  margin: 20px 
  position: relative 
  width: 100% 
  // max-width: 1200px 
  color: white
  +dark
    background: $n7
  +m
    padding: 0px 
    margin: 0px


.nav
    display: flex
    margin-bottom: 32px
    padding: 8px
    background: $n2
    border-radius: 20px
    +m
        margin-bottom: 24px
    +dark
        background: $shades4

.item
    &.active
        background: $n1
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        +dark
            background: $n6
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06)
            
.title  
  color: white 
  font-size: 24px 
  margin-bottom: 20px 
 

.header  
  display: flex 
  justify-content: space-between 
  align-items: center 

.tabs  
  display: flex 
  gap: 10px 
  position: absolute 
  top: 20px 
  background: $n2
  right: 10px
  display: flex
  margin-bottom: 32px
  padding: 8px
  background: $n2
  border-radius: 32px
  +m
      margin-bottom: 24px
  +dark
      background: $n8

  +m
    top: 50px 
  .tabButton  
    color: $n8
    padding: 10px 20px 
    border: none 
    cursor: pointer 
    border-radius: 20px 
    font-size: 1.1em 
    transition: background-color 0.3s ease 
    +dark
      color: $n2

    &:hover  
      background-color: $n7
      color: $n1
     
    &.active  
      background-color: $n7
      font-weight: bold 
      color: $n3
      box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)

.arrows  
  position: absolute 
  top: 10px 
  right: 10px 
 

.arrowButton  
  background: none 
  color: #fff 
  border: none 
  cursor: pointer 
  font-size: 1.2em 
 

.mapWrapper  
  display: flex 
  align-items: flex-start 
  justify-content: space-between 
  flex-wrap: wrap 
  gap: 20px 

   

  svg  
    width: 100% 
    height: auto 
   
 

.legend  
  background: $n3
  padding: 15px 
  border-radius: 8px 
  color: $n7
  width: 30%  
  +dark
    background: $n7
    color: $n2
  h3  
    margin-bottom: 10px 
    color: $n7
    font-size: 1.5em // Adjust for better readability
    +dark
      color: $n3

  ul  
    list-style: none 
    padding: 0 
    font-size: 0.9em // Slightly reduce font size for list items
   

  li  
    display: flex 
    align-items: center 
    margin-bottom: 8px 

    span  
      display: inline-block 
      width: 16px 
      height: 16px 
      margin-right: 8px 
      border-radius: 4px  


// Responsive adjustments for smaller screens
@media (max-width: 768px)  
  .mapWrapper  
    flex-direction: column  // Stack map and legend vertically
    align-items: center 
   

  .map  
    width: 100% 
   

  .legend  
    width: 100% 
    margin-top: 20px 
   

