@import ../../styles/helpers

.head
    margin-bottom: 44px
    +t
        margin-bottom: 32px
    +m
        flex-wrap: wrap
        margin-bottom: 24px
    .form
        margin-right: auto
        +t
            width: 250px
        +m
            width: 100%
            margin: 0 0 16px
    &.hidden
        +t
            display: none

.title
    margin-right: 24px
    +m
        margin: 0 0 16px

.nav
    display: flex
    +m
        width: 100%

.link
    padding: 8px 16px
    border-radius: 8px
    +base1-s
    color: $n4
    transition: all .2s
    +m
        flex: 1
    &:hover,
    &.active
        color: $n7
        +dark
            color: $n1
    &.active
        background: $n3
        +dark
            background: $n6
    &:not(:last-child)
        margin-right: 8px
        +m
            margin-right: 0

.filters
    margin-left: 8px
    +t
        display: none

.details
    display: none

.row
    &.flex
        display: flex
        .table
            flex-shrink: 0
            width: 384px
            +w
                width: 350px
            +d
                width: 320px
            +t
                display: none
        .details

        
.page 

  display: flex

  flex-direction: column

  gap: 20px




.nfcCard 

  background-color: #1f1f1f
  color: white

  padding: 20px

  border-radius: 10px




.nfcCardDetails 

  display: flex

  justify-content: space-between




.cardInfo 

  display: flex

  flex-direction: column

  align-items: flex-start




.cardTitle 

  font-size: 18px

  margin-bottom: 10px




.cardMeta 

  font-size: 14px

  color: #a9a9a9




.cardImage 

  width: 100px

  height: 60px

  background-color: #333

  border-radius: 10px




.productsCard 

  background-color: #2a2a2a

  color: white




.head 

  display: flex
  justify-content: space-between

  align-items: center




.tableContainer 

  margin-top: 20px




.nav 

  display: flex

  gap: 10px




.link 

  padding: 10px 20px

  background-color: transparent

  color: #fff

  border: none

  cursor: pointer




.link.active 

  background-color: #4a90e2

  border-radius: 5px


            display: block


.page 
  padding: 20px


.nfcCard 
  background-color: $n1
  padding: 20px
  border-radius: 10px
  display: flex
  flex-direction: column
  margin-bottom: 20px



.nfcCardContainer 
  display: flex
  align-items: center


.nfcCardLeft 
  flex: 1


.nfcCardRight 
  flex: 2
  padding-left: 20px
  color: $n7
  +dark
      color: $n1


.cardName 
  font-size: 1.2em
  font-weight: bold
  margin-bottom: 10px


.productsCard 
  background-color: $n1
  padding: 20px
  border-radius: 10px
  color: $n1


.head 
  display: flex
  justify-content: space-between
  align-items: center


.nav 
  display: flex
  gap: 10px


.link 
  background-color: transparent
  color: #ffffff
  padding: 10px 20px
  border: 1px solid #4a4a4a
  border-radius: 5px
  cursor: pointer


.link.active 
  background-color: #4a90e2


.tableContainer 
  margin-top: 20px


.form 
  width: 100%

