.cardContainer 
  perspective: 1000px
  width: 300px
  height: 190px
  margin: 0 auto


.card 
  width: 100%
  height: 100%
  position: relative
  transform-style: preserve-3d
  transition: transform 0.6s


.isFlipped 
  transform: rotateY(180deg)


.cardFaceFront, .cardFaceBack 
  position: absolute
  width: 100%
  height: 100%
  backface-visibility: hidden


.cardFaceFront 
  z-index: 2
  transform: rotateY(0deg)


.cardFaceBack 
  transform: rotateY(180deg)


.cardImage 
  width: 100%
  height: 100%
  object-fit: cover
  border-radius: 10px

.cardContainer 
  perspective: 1000px
  width: 300px
  height: 190px
  margin: 0 auto


.card 
  width: 100%
  height: 100%
  position: relative
  transform-style: preserve-3d
  transition: transform 0.6s


.isFlipped 
  transform: rotateY(180deg)


.cardFaceFront, .cardFaceBack 
  position: absolute
  width: 100%
  height: 100%
  backface-visibility: hidden
  background-size: cover
  background-position: center
  border-radius: 10px


.cardFaceFront 
  z-index: 2
  transform: rotateY(0deg)


.cardFaceBack 
  transform: rotateY(180deg)

